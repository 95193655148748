.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left:hover span:before {
    width: 50%;
    background: #484f66;
    transition: all 0.4s ease; 
}

.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left:hover span:after {
    width: 75%;
    background: #484f66;
    transition: all 0.4s ease; 
}

.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left:hover span{
    background: #484f66;
    transition: all 0.4s ease; 
}

.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left.active span{
    background: #ffffff;
    transition: all 0.4s ease; 
}

.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left.active span:after,
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.kt-header-mobile__toggler--left.active span:before {
    width: 100%;
    background: #ffffff;
    transition: all 0.4s ease; 
}

.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler--active i, 
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler:hover i,
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler--active i:before, 
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler:hover i:before {
    color: #484f66;
    transition: all 0.4s ease; 
} 


.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler.active i:before{
    color: #ffffff; 
    transition: all 0.4s ease; 
}

.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.active span,
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.active span::before,
.kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler.active span::after{
    background: #ffffff;
    transition: all 0.4s ease; 
}

.kt-header-custom-mobile__toggler{
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    width: 24px;
    height: 24px;
    transition: all 0.4s ease;
}

.kt-header-custom-mobile__toggler span {
    display: block;
    position: absolute;
    top: 11px;
    height: 2px;
    min-height: 2px;
    width: 100%;
    border-radius: 2px;
    background: #484f66;
    transition: all 0.4s ease; 
}

.kt-header-custom-mobile__toggler span::before, .kt-header-custom-mobile__toggler span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    min-height: 2px;
    content: "";
    border-radius: 2px;
    background: #484f66;
    transition: all 0.4s ease; 
}
.kt-header-custom-mobile__toggler span::before {
    top: -8px; 
}
.kt-header-custom-mobile__toggler span::after {
    bottom: -8px; 
}

.kt-header-custom-mobile__toggler.active span, 
.kt-header-custom-mobile__toggler.active span::before, 
.kt-header-custom-mobile__toggler.active span::after{
    background: #ffffff;
    transition: all 0.4s ease; 
}

.mobile_div_flex{
    display: flex;
    gap: 10px;
    padding: 0px;

    &.wrap{
        flex-wrap: wrap;
    }

    &.column{
        flex-direction: column;
    }

    &.column_mobile760px{
        @media (max-width: 760px){
            flex-direction: column;
        }
    }

    &.column_mobile430px{
        gap: 0px;

        @media (max-width: 430px){
            flex-direction: column;
            gap: 10px;
        }
    }
    
    &.gap5px{
        gap: 5px
    }
    
    &.gap0px{
        gap: 0px
    }

    &.padding5px{
        padding: 5px;
    }

    &.padding10px{
        padding: 10px;
    }
}

.yookasa_mobile_secret{
    font-size: 1rem;
    text-overflow: ellipsis;

    @media (max-width: 400px){
        font-size: 0.9rem;
    }

    @media (max-width: 350px){
        font-size: 0.8rem;
    }
}


.tab_mobile-button_blockedIP{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 10px;
    min-width: 100px;
    height: 30px;
    font-size: 1rem;
    outline: none;
    border: none;
    background-color: #ffffff;
    color: #000;
    transition: all 0.4s ease;

    &:hover{
        background-color: #6c85ff;
        color: #ffffff;
    }

    &:active{
        background-color: #5d78ff;
        color: #ffffff;
    }

    &.active{
        background-color: #5d78ff;
        color: #ffffff;
    }

    &:first-of-type{
        border-top-left-radius: 4px;
    }

    &:last-of-type{
        border-top-right-radius: 4px;
    }

    @media (max-width: 355px){
        font-size: 0.85rem;
    }
}


.goods_list_mobile{
    &.main_row{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 0px 10px 15px;
        gap: 10px;
    }

    &.row{
        display: flex;
        width: 100%;
        gap: 5px;
        align-items: center;        
    }

    &.good_name{
        width: auto;
        min-width: 110px;
        padding: 0px !important;

        @media (max-width: 430px){
            width: auto;
            flex: 1;
        }
    }

    &.good_info{
        width: 130px;
        padding: 0px !important;

        @media (max-width: 430px){
            width: auto;
            flex: 1;
        }
    }

    &.good_action{
        width: 120px;
        padding: 0px !important;
    }
}

.paddingTop-mobile{
    @media (max-width: 1024px){
        padding-top: 50px;
    }
}

.head_mobile_btn{    
    width: 32px;
    height: 32px;
    border: none;
    outline: none;
    border-radius: 4px !important;
    padding: 0px;
    background-color: #00000000;
    color: #484f66;
    font-size: 2rem;
    transition: all 0.4s ease;

    &:hover{
        color: #ffffff;
    }

    &:active{
        color: #ffffff;
    }

    @media (min-width: 1024px){
        display: none;
    }
}

.head_mobile_btn .la.la-refresh {
    font-size: 2rem;
}